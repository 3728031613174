<template>
  <Toast />

  <ul class="list-none p-0 m-0 flex align-items-center font-medium mb-3">
    <li>
      <a class="text-500 no-underline line-height-3 cursor-pointer"
        >Configuraciones</a
      >
    </li>
    <li class="px-2">
      <i class="pi pi-angle-right text-500 line-height-3"></i>
    </li>
    <li>
      <span class="text-900 line-height-3">Mi Empresa</span>
    </li>
  </ul>
  <div class="grid">
    <div class="col-12 md:col-6">
      <div class="card p-fluid">
        <h5><strong>Empresa</strong></h5>
        <div class="field">
          <label for="nombre"><strong>Nombre:</strong></label>
          <InputText id="nombre" type="text" v-model="empresa.nombre" />
        </div>
        <div class="field">
          <label for="direccion"><strong>Direcci&oacute;n:</strong></label>
          <InputText id="direccion" type="text" v-model="empresa.direccion" />
        </div>
        <div class="field">
          <label for="nit"><strong>Nit:</strong></label>
          <InputText
            id="nit"
            type="text"
            v-model="empresa.nit"
            placeholder="123456789012"
          />
        </div>
        <div class="field">
          <label for="razon_social"><strong>Raz&oacute;n Social</strong></label>
          <InputText
            id="razon_social"
            type="text"
            v-model="empresa.razon_social"
            placeholder="Raz&oacute;n Social"
          />
        </div>
        <div class="field">
          <Button class="p-button p-button-lg" label="Actualizar" @click="validarFrm" />
        </div>
      </div>
    </div>
    <div class="col-12 md:col-6">
      <div class="card p-fluid">
        <h5><strong>Logo</strong></h5>
        <div class="field">
          <img
            class="product-image"
            :src="'data:image/png;base64, ' + empresa.logo"
            alt="IMAGEN LOGO"
            width="150"
            height="90"
          />
          <FileUpload
            accept="image/*"
            id="file"
            name="file[]"
            type="file"
            enctype="multipart/form-data"
            :customUpload="true"
            @uploader="updatedImg"
            uploadLabel="Cargar Logo"
            mode="basic"
            label="Cargar Logo"
            v-tooltip.top="'Cargar Logo'"
            chooseLabel="Importar"
            class="p-button-outlined mr-2"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EmpresaService from "@/service/EmpresaService";

export default {
  data() {
    return {
      empresa: {},
    };
  },
  empresaService: null,

  created() {
    this.empresaService = new EmpresaService();
    this.empresaService.getFirstEmpresa().then((response) => {
      this.empresa = { ...response.data };
    });
  },
  methods: {
    validarFrm() {
      //actualizar datos

      this.empresaService
        .updateEmpresa(this.empresa.id, this.empresa)
        .then((response) => {
          console.log(response);
          this.$toast.add({
            severity: "success",
            summary: "Actualizado",
            detail: "Empresa actualizada correctamente",
            life: 3000,
          });
        });
    },
    updatedImg(event) {
      let empresa_id = this.empresa.id;
      console.log(empresa_id);
      let archivo = event.files[0];
      let formData = new FormData();
      formData.append("logo", archivo);
      formData.append("id", empresa_id);
      formData.append("nombre", this.empresa.nombre);
      formData.append("direccion", this.empresa.direccion);
      formData.append("razon_social", this.empresa.razon_social);
      formData.append("nit", this.empresa.nit);

      this.empresaService
        .updateEmpresaLogo(empresa_id, formData)
        .then((response) => {
          console.log(response);
          this.$toast.add({
            severity: "success",
            summary: "Actualizado",
            detail: "Empresa actualizada correctamente",
            life: 3000,
          });
        });
    },
  },
};
</script>
